import { createMemoryHistory, createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    name: 'home',
    path: '/home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home.vue'),
  },

  {
    path: '/gxw',
    name: 'gxw',
    redirect: '/nurse',
    component: () =>
      import(/* webpackChunkName: "gxwIndex" */ '../views/gxw/gxwIndex.vue'),
    children: [
      {
        path: '/nurse',
        name: 'nurse',
        component: () =>
          import(/* webpackChunkName: "nurse" */ '../views/gxw/nurse.vue'),
      },

      {
        path: '/lessons',
        name: 'lessons',
        component: () =>
          import(/* webpackChunkName: "lessons" */ '../views/gxw/lessons.vue'),
      },
    ],
  },

  {
    path: '/meixiwj',
    name: 'meixiwj',
    redirect: '/meixiwjdetail',
    component: () =>
      import(/* webpackChunkName: "wjIndex" */ '../views/meixiwj/wjIndex.vue'),
    children: [
      {
        path: '/meixiwjdetail',
        name: 'meixiwjdetail',
        component: () =>
          import(/* webpackChunkName: "wjdetail" */ '../views/meixiwj/wjdetail.vue'),
      },
      {
        path: '/meixiwjresult',
        name: 'meixiwjresult',
        component: () =>
          import(/* webpackChunkName: "wjresult" */ '../views/meixiwj/result.vue')
      }
    ],
  },
  {
    path: '/luckyDraw',
    name: 'luckyDraw',
    redirect: '/luckyDrawDetail',
    component: () =>
      import(/* webpackChunkName: "luckyDraw" */ '../views/luckyDraw/index.vue'),
    children: [
      {
        path: '/luckyDrawDetail',
        name: 'luckyDrawDetail',
        component: () =>
          import(/* webpackChunkName: "luckyDrawDetail  Copy" */ '../views/luckyDraw/luckyDraw.vue'),
      }
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
