import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import TDesign from 'tdesign-mobile-vue'

import './style/index.less'
import './router/permission'

// 淘宝适配方案
import './assets/js/flexible'
// 完整加载
import VueLuckyCanvas from '@lucky-canvas/vue'

createApp(App).use(router).use(TDesign).use(VueLuckyCanvas).mount('#app')
